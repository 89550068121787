<template>
  <div class="email">
    <div class="popup">
      <div class="true"><img src="../assets/icons/true.png" alt="" /></div>
      <div class="title">Email envoyé à votre boîte mail</div>
      <div class="subtitle">{{ email }}</div>
      <div class="cnt">
        Cliquer sur le lien dans l'email que vous recevrez de Victory-Partners pour passer
        le test. <span style="color:red">(verifier spam !)</span>
      </div>
      <div class="cnt" style='margin-bottom: 0;'>
        لإجراء الاختبار اضغط على الرابط الموجود في بريدك الإلكتروني من <br />
        Victory-partners
      </div>
      <div style='text-align:center'><img src='@/assets/icons/alert.png' width='70'></div>
      <div class="back" style="font-size: 15px;color:red">
        في حالة عدم توصلك بالبريد الإلكتروني خلال مدة قصيرة يرجى الإتصال على الأرقام التالية
        <br />
        +212 5 22 03 04 99 | +212 6 17 76 41 52
      </div>
      <div class="back">
        Si vous avez écrit faux email
        <router-link to="/" exact>Renvoyer le formulaire</router-link>. vous avez un
        problème?
        <a href="https://victory-partners.ma/contactez-nous/">Contactez-nous</a>.
      </div>
    </div>
  </div>
</template>

<script>
window.onbeforeunload = null;
import router from "@/router";
export default {
  name: "GoToEmail",
  data() {
    return {
      email: this.$route.params.email,
    };
  },
  created: function () {
    if (!this.$route.params.email) router.push("/");
  },
};
</script>

<style>
#nav {
  display: flex;
}
@media screen and (max-width: 768px) {
  #nav {
    text-align: center;
    display: block;
  }
  #nav ul li {
    float: none;
  }
  #nav ul {
    padding-right: 0;
  }
  #logo {
    padding: 0;
    width: 100%;
  }
  #logo img,
  #logo span {
    margin: auto;
  }
}
.email {
  height: calc(100vh - 58px);
  display: flex;
  align-items: center;
}
.popup {
  margin: auto;
  background-color: #fafafc;
  border: 1px solid #e9e9eb;
  box-shadow: 0 0 20px 0 #d2d2d2;
  padding: 0 40px;
}
.popup div {
  width: 100%;
  margin: 20px auto;
  max-width: 600px;
  overflow-wrap: break-word;
}
.popup .true {
  text-align: center;
}
.popup .true img {
  width: 100px;
}
.popup .title {
  text-align: center;
  color: #2ba25e;
  font-weight: bold;
  font-size: 20px;
}
.popup .subtitle {
  text-align: center;
  font-size: 17px;
}
.popup .cnt {
  text-align: center;
  margin-bottom: 50px;
}
.popup .back {
  text-align: center;
  font-size: 12px;
  margin-bottom: 5px;
  color: #898181;
}
.popup a {
  text-decoration: underline;
  color: blue;
}
</style>
